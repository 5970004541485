<template>
  <div class="m-0 w-100">
    <upload ref="upload" :currentPath="currentPath" :guestName="guestName">
    </upload>
    <modal-user-guest ref="modal-user-guest" v-on:user="loginGuest">
    </modal-user-guest>
    <modal-password-guest
      ref="modal-password-guest"
      v-on:user="loginGuestPassword"
    >
    </modal-password-guest>
    <modal-image ref="modal-image" :imageBytes="imageBytes"> </modal-image>
    <div class="row">
      <div class="col-md-3 pr-0">
        <div class="ibox float-e-margins">
          <div
            class="ibox-content sidebar-content"
            v-on:click.self="unselectAll"
          >
            <div class="file-manager">
              <modal-new-folder
                ref="modal-new-folder"
                :currentPath="currentPath"
                v-on:refresh="loadInformation"
              >
              </modal-new-folder>

              <div class="hr-line-dashed"></div>

              <h5>Folders</h5>
              <!-- New Tree View -->

              <ul class="folder-list" style="padding: 0">
                <tree-view
                  :node="contentTree"
                  :root="true"
                  :currentPath="currentPath"
                  :updateSection="updateContentTree"
                  ref="tree-view"
                  v-on:toFolder="toFolder($event)"
                  v-on:emitOpenMenuElement="emitOpenMenuElement($event)"
                ></tree-view>
              </ul>

              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-9 animated fadeInRight items-content"
        @contextmenu.prevent="openMenu($event)"
        v-on:click.exact.self="unselectAll"
      >
        <div class="row navbar-document pt-2 pb-2">
          <div class="col-8 pt-2">
            <el-button
              v-if="currentPath.parent.path != null"
              @click="backFolder()"
              type="default"
              class="btn-sm mr-2"
            >
              <span class="fa fa-backward" aria-hidden="true"></span>
            </el-button>
            <span class="path" v-if="currentPath.path !== null">
              <a href="#" @click.prevent="toFolder('/')">
                Document Manager
              </a>
              /
              {{
                currentPath.path != "/"
                  ? currentPath.path.split("/").join(" / ")
                  : ""
              }}</span
            >
          </div>
          <div class="col-4">
            <el-input
              placeholder="Search Here..."
              @keyup.enter.native="searchContent"
              v-model="searchBox"
              class="p-0"
            />
          </div>
          <div class="col-10 mt-1 mb-2 buttons-content">
            <button
              class="btn btn-primary btn-block mr-1 mt-0"
              @click="chooseFiles()"
            >
              <i class="fa fa-file-plus" aria-hidden="true"></i>
              Upload Files
            </button>
            <button
            v-permission="['administrator','office']"
              v-if="$route.params.token === undefined"
              class="btn btn-primary btn-block mr-1 mt-0"
              @click="$refs['modal-new-folder'].show_form_new_folder()"
            >
              <i class="fa fa-folder-plus" aria-hidden="true"></i>
              New Folder
            </button>
            <button
              v-permission="['administrator','office']"
              v-if="$route.params.token === undefined"
              v-show="
                clipboardElementLength() >= 2 && !clipboard.somethingProtected
              "
              class="btn btn-secondary btn-block mr-1 mt-0"
              @click="copy()"
            >
              <i class="fa fa-copy" aria-hidden="true"></i>
              Copy
            </button>
            <button
              v-permission="['administrator','office']"
              v-if="$route.params.token === undefined"
              v-show="
                clipboardElementLength() >= 2 && !clipboard.somethingProtected
              "
              class="btn btn-secondary btn-block mr-1 mt-0"
              @click="cut()"
            >
              <i class="fa fa-cut" aria-hidden="true"></i>
              Cut
            </button>
            <button
              v-permission="['administrator','office']"
              v-if="$route.params.token === undefined"
              v-show="
                clipboardElementLength() >= 2 && !clipboard.somethingProtected
              "
              class="btn btn-secondary btn-block mr-1 mt-0"
              @click="deleteItems()"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
              Delete
            </button>
            <button
              v-show="
                clipboardElementLength() >= 2 && !clipboard.somethingProtected
              "
              class="btn btn-secondary btn-block mr-1 mt-0"
              @click="startDownload()"
            >
              <i class="fa fa-download" aria-hidden="true"></i>
              Download
            </button>
            <button
              v-permission="['administrator','office']"
              v-if="$route.params.token === undefined"
              v-show="clipboardElementLength() >= 1"
              class="btn btn-secondary btn-block mr-1 mt-0"
              @click="startShare()"
            >
              <i class="fa fa-share" aria-hidden="true"></i>
              Share
            </button>
            <button
              v-permission="['administrator','office']"
              v-if="$route.params.token === undefined"
              v-show="waitPaste.status"
              class="btn btn-info btn-block mr-1 mt-0"
              @click="$refs['paste'].paste()"
            >
              <i class="fa fa-paste" aria-hidden="true"></i>
              Paste
            </button>
          </div>
          <div class="col-2 mt-1 mb-2 buttons-view-content">
            <el-button class="btn-sm" @click="tableView">
              <span class="fa fa-bars" aria-hidden="true"></span>
            </el-button>
            <el-button class="btn-sm mr-1" @click="gridView">
              <span class="fa fa-grip-horizontal" aria-hidden="true"></span>
            </el-button>
            <el-button class="btn-sm" @click="refresh()">
              <span class="fa fa-sync-alt" aria-hidden="true"></span>
            </el-button>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-lg-12 pr-0" v-on:click.exact.self="unselectAll">
            <div class="grid-view" v-if="view === 'grid'">
              <div
                class="file-box"
                v-on:click.exact.self="unselectAll"
                v-for="item in contentData.directories"
                :key="item.id"
                v-on:dblclick="toFolder(item.path)"
              >
                <div
                  class="file"
                  @click.ctrl.exact="onCtrlClick($event, item)"
                  @click.exact="selectElement($event, item)"
                  @contextmenu.prevent="openMenuElement($event, item)"
                >
                  <span class="corner"></span>

                  <div class="icon">
                    <i class="fa fa-folder"></i>
                  </div>
                  <div class="file-name">
                    {{ item.basename }}
                    <br />
                    <small>{{ item.timestamp }}</small>
                  </div>
                </div>
              </div>
              <div
                class="file-box"
                v-for="item in contentData.files"
                :key="item.id"
                v-on:dblclick="openFile(item.path)"
              >
                <div
                  class="file"
                  @click.ctrl.exact="onCtrlClick($event, item)"
                  @click.exact="selectElement($event, item)"
                  @contextmenu.prevent="openMenuElement($event, item)"
                >
                  <span class="corner"></span>
                  <div class="icon">
                    <i class="fa fa-file"></i>
                  </div>
                  <div class="file-name">
                    {{ item.basename }}
                    <br />
                    <small>{{ item.timestamp }}</small>
                  </div>
                </div>
              </div>
            </div>
            <el-table
              v-if="view === 'table'"
              :data="contentTable()"
              :row-class-name="tableRowClassName"
              class="w-100"
            >
              <el-table-column width="40">
                <template slot="header">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-on:change="selectAllOnTable()"
                    v-model="selectAllTable"
                  />
                </template>
                <template slot-scope="scope">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-on:change="selectElementTable(scope.row)"
                      v-model="scope.row.selected"
                      type="checkbox"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="basename" label="Name">
                <template slot-scope="scope">
                  <div class="icon-table">
                    <div
                      v-if="scope.row.type == 'dir'"
                      v-on:dblclick="toFolder(scope.row.path)"
                      style="cursor:pointer;"
                    >
                      <span class="far fa-folder" style="color:#fdb900;"></span>
                      {{ scope.row.basename }}
                    </div>
                    <div
                      v-else
                      v-on:dblclick="openFile(scope.row.path)"
                      style="cursor:pointer;"
                    >
                      <span class="far fa-file"></span>
                      {{ scope.row.basename }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                width="100"
                prop="timestamp"
                label="Date"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column width="350">
                <template slot-scope="scope">
                  <el-button-group
                    class="float-right"
                    v-if="$route.params.token === undefined"
                  >
                    <el-button
                      size="small"
                      :disabled="clipboardFunctions()"
                      icon="fal fa-share"
                      v-permission="['administrator','office']"
                      @click="startShare(scope.row)"
                    ></el-button>
                    <el-button
                      size="small"
                      v-if="!scope.row.protected && scope.row.active == 1 && $store.getters.discriminator !== 'shareholder'"
                      :disabled="clipboardFunctions()"
                      icon="fal fa-edit"
                      @click="startRename(scope.row)"
                    ></el-button>
                    <el-button
                      size="small"
                      :disabled="clipboardFunctions()"
                      icon="fal fa-download"
                      @click="startDownload(scope.row)"
                    ></el-button>
                    <el-button
                      size="small"
                      v-if="!scope.row.protected && scope.row.active == 1"
                      :disabled="clipboardFunctions()"
                      icon="fal fa-copy"
                      v-permission="['administrator','office']"
                      @click="copy(scope.row)"
                    ></el-button>
                    <el-button
                      size="small"
                      v-if="!scope.row.protected && scope.row.active == 1"
                      :disabled="clipboardFunctions()"
                      icon="fal fa-cut"
                      v-permission="['administrator','office']"
                      @click="cut(scope.row)"
                    ></el-button>
                    <el-button
                      size="small"
                      v-if="!scope.row.protected && scope.row.active == 1"
                      :disabled="clipboardFunctions()"
                      icon="fal fa-trash"
                      v-permission="['administrator','office']"
                      @click="deleteItems(scope.row)"
                    ></el-button>
                  </el-button-group>
                  <el-button-group class="float-right" v-else>
                    <el-button
                      size="small"
                      :disabled="clipboardFunctions()"
                      icon="fal fa-download"
                      @click="startDownload(scope.row)"
                    ></el-button>
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <vue-context ref="menu">
      <li v-if="$route.params.token === undefined && !currentPath.searchActive && $store.getters.discriminator !== 'shareholder'">
        <a
          href="#"
          @click.prevent="$refs['modal-new-folder'].show_form_new_folder()"
        >
          <i class="fal fa-folder-plus"></i> New Folder
        </a>
      </li>
      <li
        v-if="
          waitPaste.status &&
            $route.params.token === undefined &&
            !currentPath.searchActive
        "
      >
        <a href="#" @click.prevent="$refs['paste'].paste()">
          <i class="fal fa-paste"></i> Paste
        </a>
      </li>
      <li v-if="currentPath.searchActive">
        <div class="alert-info mt-1" role="alert">
          No actions
        </div>
      </li>
    </vue-context>
    <vue-context
      ref="menuElement"
      :close-on-click="true"
      :close-on-scroll="true"
    >
      <li v-if="$route.params.token === undefined && !currentPath.searchActive && $store.getters.discriminator !== 'shareholder'">
        <a
          href="#"
          @click.prevent="$refs['modal-new-folder'].show_form_new_folder()"
        >
          <i class="fal fa-folder-plus"></i> New Folder
        </a>
      </li>
      <hr class="m-0" />
      <li
        v-permission="['administrator','office']"
        v-if="
          rulesMenuElement() &&
            currentMenuElement.active == 1 &&
            !currentPath.searchActive
        "
      >
        <a href="#" @click.prevent="copy(currentMenuElement)">
          <i class="fal fa-copy"></i> Copy
        </a>
      </li>
      <li
        v-permission="['administrator','office']"
        v-if="
          rulesMenuElement() &&
            currentMenuElement.active == 1 &&
            !currentPath.searchActive
        "
      >
        <a href="#" @click.prevent="cut(currentMenuElement)">
          <i class="fal fa-cut"></i> Cut
        </a>
      </li>
      <li
        v-permission="['administrator','office']"
        v-if="
          waitPaste.status &&
            rulesMenuElement() &&
            currentMenuElement.active == 1 &&
            !currentPath.searchActive
        "
      >
        <a href="#" @click.prevent="$refs['paste'].paste()">
          <i class="fal fa-paste"></i> Paste
        </a>
      </li>
      <li
        v-permission="['administrator','office']"
        v-if="
          clipboardElementLength() <= 1 &&
            rulesMenuElement() &&
            currentMenuElement.active == 1 &&
            !currentPath.searchActive
        "
      >
        <a href="#" @click.prevent="startRename(currentMenuElement)">
          <i class="fal fa-edit"></i> Rename
        </a>
      </li>
      <li v-permission="['administrator','office']" v-if="$route.params.token === undefined">
        <a href="#" @click.prevent="startShare(currentMenuElement)">
          <i class="fal fa-share"></i> Share
        </a>
      </li>
      <li v-if="currentMenuElement.active == 1">
        <a href="#" @click.prevent="startDownload(currentMenuElement)">
          <i class="fal fa-download"></i> Download
        </a>
      </li>
      <hr class="m-0" v-if="currentMenuElement.active == 1" />
      <li
        v-permission="['administrator','office']"
        v-if="
          rulesMenuElement() ||
            (currentMenuElement.active == 0 && !currentPath.searchActive)
        "
      >
        <a href="#" @click.prevent="deleteItems(currentMenuElement)">
          <i class="fal fa-trash"></i> Delete
        </a>
      </li>
      <li v-if="currentMenuElement.protected && currentMenuElement.active == 1">
        <div class="alert mt-1" role="alert">
          Protected Element!
        </div>
      </li>
      <li v-if="currentMenuElement.active == 0">
        <div class="alert-danger mt-1" role="alert">
          Disabled Element!
        </div>
      </li>
    </vue-context>
    <!-- Modal rename -->
    <modal-rename
      ref="modal-rename"
      :currentPath="currentPath"
      v-on:refresh="loadInformation"
    >
    </modal-rename>

    <!-- Modal paste -->

    <paste
      ref="paste"
      :currentPath="currentPath"
      :waitPaste="waitPaste"
      :unselectAll="unselectAll"
      v-on:refresh="loadInformation"
      v-on:unselectAll="unselectAll"
    >
    </paste>

    <!-- Modal Share -->
    <modal-share ref="modal-share" v-on:unselectAll="unselectAll">
    </modal-share>
    <router-link
      v-permission="['administrator','office']"
      :to="`/admin/document-manager/panel`"
      v-if="this.$route.params.token === undefined"
    >
      <el-button class="fixed-bottom new-register ml-1" type="primary"
        >Control Panel</el-button
      >
    </router-link>
  </div>
</template>

<script>
import { VueContext } from "vue-context";
import documentManager from "@/services/api/documentManager";
//components
import ModalNewFolder from "@/components/document-manager/ModalFolder";
import ModalShare from "@/components/document-manager/ModalShare";
import ModalUserGuest from "@/components/document-manager/ModalUserGuest";
import ModalPasswordGuest from "@/components/document-manager/ModalPasswordGuest";
import ModalRename from "@/components/document-manager/ModalRename";
import ModalImage from "@/components/document-manager/ModalImage";
import Paste from "@/components/document-manager/Paste";
import TreeView from "@/components/document-manager/TreeView";
import Upload from "@/components/document-manager/Upload";

import axios from "axios";

export default {
  components: {
    VueContext,
    ModalNewFolder,
    ModalShare,
    ModalRename,
    Paste,
    TreeView,
    ModalUserGuest,
    ModalPasswordGuest,
    ModalImage,
    Upload,
  },
  data() {
    return {
      guestName: null,
      view: "table",
      selectAllTable: false,
      imageBytes: "",
      currentPath: {
        searchActive: false,
        parent: {
          parent: null,
          path: null,
        },
        path: null,
      },
      searchBox: null,
      contentData: {
        directories: null,
        files: null,
      },
      contentTree: {
        basename: null,
        children: [],
        path: "/",
        hasSubdirectories: false,
      },
      updateContentTree: {
        content: [],
        path: null,
      },
      waitPaste: {
        status: false,
        path: null,
        clipboard: {},
      },
      currentMenuElement: {
        protected: false,
      },
      clipboard: {
        somethingProtected: false,
        type: null,
        files: [],
        directories: [],
      },
    };
  },
  mounted() {
    if (this.$route.params.token !== undefined) {
      this.$store.dispatch("logout").then((response) => {
        documentManager
          .loginGuest(this.$route.params.token)
          .then((response) => {
            if (response.needSecurityCode === false) {
              this.$refs["modal-user-guest"].show_form_user_guest();
              this.$store.dispatch("token", {
                token: response.token,
              });
              localStorage.setItem("access", response.token);
              this.currentPath.path = response.user.root_path;
              this.loadInformation(true);
            } else {
              this.$refs[
                "modal-password-guest"
              ].show_form_user_password_guest();
            }
          })
          .catch((err) => {
            this.currentPath.path = "/";
          });
      });
    } else {
      this.currentPath.path = "/";
      this.loadInformation(true);
    }
  },
  methods: {
    loginGuest(event) {
      this.guestName = event.user;
    },
    loginGuestPassword(event) {
      this.guestName = event.user.name;
      let data = {
        token: this.$route.params.token,
        password: event.user.password,
      };
      documentManager
        .loginGuestPassword(data)
        .then((response) => {
          this.$store.dispatch("token", {
            token: response.token,
          });
          localStorage.setItem("access", response.token);
          this.currentPath.path = response.user.root_path;
          this.loadInformation(true);
        })
        .catch((err) => {
          this.$message({
            message: "Password Incorrect",
            type: "error",
            customClass: "message-error",
          });
          this.$refs["modal-password-guest"].show_form_user_password_guest();
        });
    },
    loadInformation(initial = false) {
      this.currentPath.searchActive = false;
      documentManager.content(this.currentPath).then((response) => {
        response.files.forEach((element) => {
          element.selected = false;
        });
        response.directories.forEach((element) => {
          element.selected = false;
        });
        this.contentData = response;
      });
      documentManager.tree(this.currentPath).then((response) => {
        response.directories.forEach((element) => {
          element.children = [];
        });
        if (initial) {
          this.contentTree = {
            basename: null,
            children: response.directories,
            path: "/",
            hasSubdirectories: false,
          };
        } else {
          this.updateContentTree.content = response.directories;
          this.updateContentTree.path = this.currentPath.path;
        }
      });
    },
    searchContent() {
      if (this.searchBox != "" && this.searchBox != null) {
        const data = {
          path: this.currentPath.path,
          search: this.searchBox,
        };
        this.currentPath.searchActive = true;
        documentManager
          .searchBox(data)
          .then((response) => {
            response.files.forEach((element) => {
              element.selected = false;
            });
            response.directories.forEach((element) => {
              element.selected = false;
            });
            this.contentData = response;
          })
          .catch((err) => {
            this.$message({
              message: "Oopss... Something wrong",
              type: "error",
              customClass: "message-error",
            });
          });
      } else {
        this.currentPath.searchActive = false;
        this.toFolder(this.currentPath.path);
      }
    },
    openFile(pathStr) {
      const data = {
        path: pathStr,
      };
      const path = require("path");
      const ext = path.extname(pathStr);

      const imageExt = [".jpg", ".jpeg", ".png"];
      const pdfExt = [".pdf"];
      this.$message({
        message: "Just wait a moment.",
        type: "info",
        customClass: "message-info",
      });
      if (imageExt.includes(ext)) {
        documentManager
          .show(data)
          .then((response) => {
            this.imageBytes = response;
            this.$refs["modal-image"].show_modal_image();
          })
          .catch((err) => {
            this.$message({
              message: "Oopss... Something wrong",
              type: "error",
              customClass: "message-error",
            });
          });
      } else if (pdfExt.includes(ext)) {
        documentManager
          .getUrl(data)
          .then((response) => {
            window.open(response.result.message,'_blank');
          })
          .catch((err) => {
            this.$message({
              message: "Oopss... Something wrong",
              type: "error",
              customClass: "message-error",
            });
          });
        /* window.open("data:application/pdf;base64, " + response); */
      }
    },
    contentTable() {
      if (this.contentData.files !== null) {
        return this.contentData.files.concat(this.contentData.directories);
      } else if (this.contentData.directories != null) {
        return this.contentData.directories;
      } else {
        return [];
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.selected == true) {
        return "selected-row";
      } else {
        return "";
      }
    },
    selectAllOnTable() {
      let auxFiles = Object.assign([], this.contentData.files);
      let auxDirectories = Object.assign([], this.contentData.directories);
      if (this.selectAllTable) {
        auxFiles.forEach((element) => {
          if (element.selected === false) {
            element.selected = true;
            this.clipboardElement(element);
          }
        });
        auxDirectories.forEach((element) => {
          if (element.selected === false) {
            element.selected = true;
            this.clipboardElement(element);
          }
        });
      } else {
        auxFiles.forEach((element) => {
          element.selected = false;
          this.clipboardElement(element);
        });
        auxDirectories.forEach((element) => {
          element.selected = false;
          this.clipboardElement(element);
        });
      }
      this.contentData.files = Object.assign([], auxFiles);
      this.contentData.directories = Object.assign([], auxDirectories);
    },
    tableView() {
      this.unselectAll();
      this.view = "table";
    },
    gridView() {
      this.unselectAll();
      this.view = "grid";
    },
    startShare(item = null) {
      if (item != null) {
        item.selected = true;
        this.clipboardElement(item);
      }
      const items = {
        items: [...this.clipboard.directories, ...this.clipboard.files],
      };
      this.$refs["modal-share"].startShare(items);
    },
    startRename(item = null) {
      if (item == null) {
        item = this.currentMenuElement;
      }
      this.$refs["modal-rename"].startRename(item);
    },
    chooseFiles() {
      this.$refs["upload"].operUpload();
    },
    emitOpenMenuElement(event) {
      this.openMenuElement(event.event, event.item, true);
    },
    rulesMenuElement() {
      return (
        !this.currentMenuElement.protected &&
        this.$route.params.token === undefined
      );
    },
    openMenuElement($event, item, tree = false) {
      if (!tree) {
        this.paintSelectElement($event);
      } else {
        this.unselectAll();
      }
      this.$refs.menu.close($event);
      this.$refs.menuElement.open($event);
      if (item.selected === false) {
        item.selected = true;
        this.clipboardElement(item);
      }
      this.currentMenuElement = item;
    },
    paintSelectElement($event) {
      const parent = $event.target.closest(".file");
      var child = null;
      for (var i = 0; i < parent.childNodes.length; i++) {
        if (parent.childNodes[i].classList.contains("file-name")) {
          child = parent.childNodes[i];
          break;
        }
      }
      child.classList.add("item-selected");
    },
    alterPaintSelectElement($event) {
      const parent = $event.target.closest(".file");
      var child = null;
      for (var i = 0; i < parent.childNodes.length; i++) {
        if (parent.childNodes[i].classList.contains("file-name")) {
          child = parent.childNodes[i];
          break;
        }
      }
      child.classList.toggle("item-selected");
    },
    clearPaintSelectElement() {
      var elems = document.querySelectorAll(".file-name");
      [].forEach.call(elems, function(el) {
        el.classList.remove("item-selected");
      });
    },
    onCtrlClick($event, item) {
      if (!item.selected) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      this.alterPaintSelectElement($event);
      this.clipboardElement(item);
    },
    clipboardElementLength() {
      return this.clipboard.directories.length + this.clipboard.files.length;
    },
    clipboardFunctions() {
      return (
        this.clipboard.files.length + this.clipboard.directories.length >= 2 ||
        this.clipboard.somethingProtected
      );
    },
    clipboardElement(item) {
      switch (item.type) {
        case "dir":
          if (item.selected === false) {
            this.clipboard.directories = Object.assign(
              [],
              this.clipboard.directories.filter(
                (element) => element.path !== item.path
              )
            );
          } else {
            let find = false;
            this.clipboard.directories.forEach((itemPath) => {
              if (itemPath.path === item.path) {
                find = true;
              }
            });
            if (!find) {
              this.clipboard.directories.push(Object.assign({}, item));
            }
          }
          break;
        case "file":
          if (item.selected === false) {
            this.clipboard.files = Object.assign(
              [],
              this.clipboard.files.filter(
                (element) => element.path !== item.path
              )
            );
          } else {
            let find = false;
            this.clipboard.files.forEach((itemPath) => {
              if (itemPath.path === item.path) {
                find = true;
              }
            });
            if (!find) {
              this.clipboard.files.push(Object.assign({}, item));
            }
          }
          break;
      }
      this.clipboard.somethingProtected = false;
      [...this.clipboard.files, ...this.clipboard.directories].forEach(
        (item) => {
          if (item.protected) {
            this.clipboard.somethingProtected = true;
          }
        }
      );
    },
    selectElementTable(row) {
      this.clipboardElement(row);
    },
    selectElement($event, item) {
      this.clipboard.directories = [];
      this.clipboard.files = [];
      this.contentData.directories.forEach((element) => {
        element.selected = false;
      });
      this.contentData.files.forEach((element) => {
        element.selected = false;
      });
      item.selected = true;
      switch (item.type) {
        case "dir":
          this.clipboard.directories.push(Object.assign({}, item));
          break;
        case "file":
          this.clipboard.files.push(Object.assign({}, item));
          break;
      }
      this.clearPaintSelectElement();
      this.alterPaintSelectElement($event);
    },
    unselectAll() {
      this.clipboard.directories = [];
      this.clipboard.files = [];
      this.clearPaintSelectElement();
      this.contentData.directories.forEach((element) => {
        element.selected = false;
      });
      this.contentData.files.forEach((element) => {
        element.selected = false;
      });
      this.clipboard.somethingProtected = false;
    },
    startDownload(item = null) {
      if (item != null) {
        item.selected = true;
        this.clipboardElement(item);
      }

      if (
        this.clipboard.files.length === 1 &&
        this.clipboard.directories.length === 0
      ) {
        this.download(item);
        this.unselectAll();
      } else {
        this.$message({
          message:
            "Zipping " +
            (this.clipboard.files.length + this.clipboard.directories.length) +
            " elements",
          type: "info",
          customClass: "message-info",
        });
        documentManager
          .makeZip({ items: this.clipboard })
          .then((response) => {
            this.$message({
              message: "Zipped Successfully",
              type: "success",
              customClass: "message-success",
            });
            this.download(response.result);
          })
          .catch(() => {
            this.$message({
              message: "Can't Make Zip",
              type: "error",
              customClass: "message-error",
            });
          });
      }
    },
    forceFileDownload(response, name) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    download(item) {
      const token = this.$store.getters.token;
      if (token != null) {
        setTimeout(() => {
          this.$message({
            message: "Downloading...",
            type: "info",
            customClass: "message-info",
          });
        }, 100);
        this.$http({
          method: "get",
          headers: {
            Authorization: "Bearer " + token,
          },
          url:
            axios.defaults.baseURL + "/filemanager/download",
          params: {path: item.path},
          responseType: "arraybuffer",
        })
          .then((response) => {
            this.forceFileDownload(response, item.basename);
          })
          .catch(() => console.log("error occured"));
      }
    },
    copy(item = null) {
      if (item != null) {
        item.selected = true;
        this.clipboardElement(item);
      }
      this.waitPaste.status = true;
      this.waitPaste.clipboard = Object.assign({}, this.clipboard);
      this.waitPaste.type = "copy";
    },
    cut(item = null) {
      if (item != null) {
        item.selected = true;
        this.clipboardElement(item);
      }
      this.waitPaste.status = true;
      this.waitPaste.clipboard = Object.assign({}, this.clipboard);
      this.waitPaste.type = "cut";
    },
    deleteItems(item = null) {
      if (item != null) {
        item.selected = true;
        this.clipboardElement(item);
      }
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          const data = {
            items: this.clipboard.directories.concat(this.clipboard.files),
          };
          documentManager
            .delete(data)
            .then((response) => {
              this.loadInformation();
              this.unselectAll();
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });
    },
    openMenu($event) {
      if (this.$route.params.token !== undefined) {
        return false;
      }
      const target = $event.target;
      if (
        target.classList.contains("col-lg-12") ||
        target.classList.contains("col-md-9") ||
        target.classList.contains("file-box")
      ) {
        this.$refs.menu.open($event);
        this.$refs.menuElement.close($event);
      }
    },
    refresh() {
      this.unselectAll();
      this.currentPath.searchActive = false;
      documentManager.content(this.currentPath).then((response) => {
        response.files.forEach((element) => {
          element.selected = false;
        });
        response.directories.forEach((element) => {
          element.selected = false;
        });
        this.contentData = response;
      });
    },
    toFolder(path) {
      this.unselectAll();
      this.currentPath.searchActive = false;
      let parent = Object.assign({}, this.currentPath);
      if (path !== "/") {
        this.currentPath.path = path;
      } else {
        this.currentPath.path = "/";
      }
      documentManager.content(this.currentPath).then((response) => {
        response.files.forEach((element) => {
          element.selected = false;
        });
        response.directories.forEach((element) => {
          element.selected = false;
        });
        this.contentData = response;
        this.currentPath.parent = parent;
      });
    },
    backFolder() {
      this.unselectAll();
      this.currentPath.searchActive = false;
      let parent = Object.assign({}, this.currentPath.parent.parent);
      if (this.currentPath.parent.path !== "/") {
        this.currentPath.path = this.currentPath.parent.path;
      } else {
        this.currentPath.path = "/";
      }
      documentManager.content(this.currentPath).then((response) => {
        response.files.forEach((element) => {
          element.selected = false;
        });
        response.directories.forEach((element) => {
          element.selected = false;
        });
        this.contentData = response;
        this.currentPath.parent = parent;
      });
    },
  },
};
</script>

<style lang="scss">
@import "~vue-context/dist/css/vue-context.css";
@import "@/assets/style/document-manager.scss";
</style>
