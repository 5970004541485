<template>
  <div>
    <modal :clickToClose="false" name="user-guest-modal"  height="auto" width="350" :scrollable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12 mb-2">
          <h4>Name</h4>
        </div>
        <el-form :model="formuser" ref="formuser" :hide-required-asterisk="true">
          <div class="row" style="padding: 15px">
            <div class="col-12">
              <el-form-item
                :label="'Name'"
                prop="name"
                :rules="[{ required: true, message: 'Name is required'}]"
              >
              <el-input type="text" v-model="formuser.name" placeholder="User Name"></el-input>
              </el-form-item>
            </div>
            <div class="col-12"> 
              <el-button type="primary" class="float-right" @click="savedataUser('formuser')">Continue</el-button>
              
            </div>
          </div>
        </el-form>
      </div>
    </modal>
  </div>
</template>

<script>

export default {
  name: 'modal-user-guest',
  data() {
    return {
      formuser: {
        name: null
      }
    };
  },
  methods: {
    savedataUser (formName){
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit("user",{user:this.formuser.name});
          this.hide_form_user_guest();
        }else{
          return false;
        }
      });
    },
    show_form_user_guest () {      
      this.$modal.show('user-guest-modal');
    },
    hide_form_user_guest () {
      this.$modal.hide('user-guest-modal');
    }
  }
};
</script>

<style lang="scss">
</style>
