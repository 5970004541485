<template>
  <div>
    <modal name="modal-image" height="auto" width="600" :scrollable="true" :draggable="true">
      <div>
        <img v-bind:src="'data:image/jpeg;base64,'+imageBytes" width="600" draggable="false"/>
      </div>
    </modal>
  </div>
</template>

<script>

export default {
  name: 'modal-image',
  props:{
    imageBytes: String
  },
  methods: {
    show_modal_image () {      
      this.$modal.show('modal-image');
    },
    hide_modal_image () {
      this.$modal.hide('modal-image');
    }
  }
};
</script>

<style lang="scss">
</style>
