<template>
  <div>
    <modal name="rename-modal" height="auto" width="400" :scrollable="true" :draggable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12 mb-2">
          <h4>Rename</h4>
        </div>
        <el-form :model="formrename" ref="formrename" :hide-required-asterisk="true">
          <div class="row" style="padding: 15px">
            <div class="col-12">
              <el-form-item
                :label="'Name'"
                prop="newName"
                :rules="[{ required: true, message: 'Name is required'}]"
              >
              <el-input type="text" v-model="formrename.newName" placeholder="New Name"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 mb-1"> 
              <el-button type="primary" class="float-right" @click="savedataFormrename('formrename')">Rename</el-button>
              <el-button type="secondary" class="float-right mr-1" @click="hide_form_rename()">Cancel</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </modal>
  </div>
</template>

<script>

import documentManager from "@/services/api/documentManager";

export default {
  name: 'modal-rename',
  props:{
    currentPath: Object
  },
  data() {
    return {
      formrename: {
        oldName: null,
        newName: null,
        path: null,
        type: null
      }
    };
  },
  methods: {
    startRename(item){
      if(this.currentPath.searchActive){
        this.$message({
              message: 'Action Blocked',
              type: "error",
              customClass: "message-error"
            });
        return false;
      }
      this.formrename.oldName=item.basename;
      this.formrename.newName=item.basename;
      this.formrename.path=this.currentPath.path;
      this.formrename.type=item.type;
      this.show_form_rename();
    },
    show_form_rename () {      
      this.$modal.show('rename-modal');
    },
    hide_form_rename () {
      this.$modal.hide('rename-modal');
    },
    savedataFormrename(formName){
      this.$refs[formName].validate(valid => {
        if (valid) {
          documentManager.rename(this.formrename).then(response => {
            this.$emit("refresh");
          });
          this.hide_form_rename();
        }else{
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
