<template>
  <div>
    <modal name="duplicate-table-modal" height="auto" width="700" :scrollable="true" :draggable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12">
          <h4>Problematic Files</h4>
        </div>
        <div class="col-12 mb-3">
          <el-table
            :data="this.problematicPaste.especialFiles" 
            class="w-100">
              <el-table-column prop="basename" width="350" label="Name"></el-table-column>
              <el-table-column prop="timestamp" width="100" label="Time Stamp"></el-table-column>
              <el-table-column prop="method" width="80">
                <template slot-scope="scope">
                  <center>
                    <i v-if="['omit','replace','rename'].includes(scope.row.method)" class="fal fa-check-circle text-success"></i>
                    <i v-else class="fal fa-times-circle text-danger"></i>
                  </center>
                </template>
              </el-table-column>
              <el-table-column label="Operations">
                <template slot-scope="scope">
                  <center>
                    <el-select v-model="scope.row.method" placeholder="Action" class="w-100">
                      <el-option :label="'Replace'" :value="'replace'"></el-option>
                      <el-option :label="'Rename'" :value="'rename'"></el-option>
                    </el-select>
                  </center>
                </template>
              </el-table-column>
          </el-table>
        </div>
        <div class="col-12 mb-1"> 
          <el-button type="primary" class="float-right" @click="pasteProblematic()">Try Again</el-button>
          <el-button type="secondary" class="float-right mr-1" @click="hide_duplicate()">Discard Files</el-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import documentManager from "@/services/api/documentManager";

export default {
  name: 'paste',
  props:{
    currentPath: Object,
    waitPaste: Object
  },
  data() {
    return {
      problematicPaste: {
        especialFiles:[]
      }
    };
  },
  methods: {
    paste(){
      if(this.currentPath.searchActive){
        this.$message({
              message: 'Action Blocked',
              type: "error",
              customClass: "message-error"
            });
        return false;
      }
      this.$emit("unselectAll");
      this.waitPaste.path=this.currentPath.path;
      this.waitPaste.clipboard.type=this.waitPaste.type;
      documentManager.paste(this.waitPaste).then(response => {
        if(response.result.status=="success"){
          this.$emit("refresh");
        }else if(response.result.status=="error"){
          this.$message({
                message: response.result.message,
                type: "error",
                customClass: "message-error"
              });
        }else if(response.result.status=="warning"){
          this.$message({
                message: 'You attempt to copy or move a file are unsuccessful',
                type: "warning",
                customClass: "message-warning"
              });
          this.problematicPaste=response.result.message;
          this.show_duplicate();
        }
      });
    },
    pasteProblematic(){
      if(this.currentPath.searchActive){
        this.$message({
              message: 'Action Blocked',
              type: "error",
              customClass: "message-error"
            });
        return false;
      }
      this.$emit("unselectAll");
      this.problematicPaste.especialFiles.forEach(elm=>{
        if(elm.method===''){
          return false;
        }
      });
      documentManager.paste(this.problematicPaste).then(response => {
        this.hide_duplicate();
        if(response.result.status=="success"){
          this.$emit("refresh");
        }else if(response.result.status=="error"){
          this.$message({
                message: response.result.message,
                type: "error",
                customClass: "message-error"
              });
        }else if(response.result.status=="warning"){
          this.$message({
                message: 'You attempt to copy or move a file are unsuccessful',
                type: "warning",
                customClass: "message-warning"
              });
          this.problematicPaste=response.result.message;
          this.show_duplicate();
        }
      });
    },
    show_duplicate () {      
      this.$modal.show('duplicate-table-modal');
    },
    hide_duplicate () {
      this.$modal.hide('duplicate-table-modal');
    }
  }
};
</script>

<style lang="scss">
</style>