<template>
  <div>
    <modal
      name="share-modal"
      height="auto"
      width="900"
      :scrollable="true"
      :draggable="true"
    >
      <div class="row" style="padding: 15px">
        <div class="col-12">
          <h4>Share</h4>
        </div>
        <div class="col-12 mb-3">
          <hr class="mb-2 mt-0" />
          <h5 class="mb-1" style="color:gray;">
            <strong>Shareholders:</strong> Select shareholders for share
          </h5>
          <el-table :data="shareContentView.shareholders" class="w-100">
            <el-table-column width="40">
              <template slot-scope="scope">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    v-model="scope.row.selected"
                    type="checkbox"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="Name">
              <template slot-scope="scope">
                {{ scope.row.name + " " + scope.row.lastName }}
              </template>
            </el-table-column>
            <el-table-column
              prop="securitySocial"
              label="Security Social"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="phone"
              label="Phone"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column label="Text Invitation">
              <template slot-scope="scope">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    v-model="scope.row.sms"
                    type="checkbox"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table>
          <hr class="mb-2 mt-0" />
          <h5 class="mb-1" style="color:gray;">
            <strong>Share with URL:</strong> Anyone on the internet with this
            link can view
          </h5>
          <el-form
            :model="formShare"
            ref="formshareUrl"
            :hide-required-asterisk="true"
          >
            <div class="row" style="padding: 15px">
              <div class="col-12 mb-4">
                <label style="color: gray;">Emails For Share With URL</label>
                <el-table
                  :data="
                    formShare.urlUsers.concat([
                      {
                        new: true,
                        name: '',
                        email: '',
                      },
                    ])
                  "
                  class="w-100"
                >
                  <el-table-column label="Name" width="300">
                    <template slot-scope="scope">
                      <div>
                        <el-form-item
                          v-if="scope.row.new === true"
                          :prop="scope.row.name"
                          class="mb-0"
                        >
                          <el-input
                            type="text"
                            v-model="scope.row.name"
                            placeholder="User Name"
                          ></el-input>
                        </el-form-item>
                        <span v-else>
                          {{ scope.row.name }}
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Email">
                    <template slot-scope="scope">
                      <div>
                        <el-form-item
                          v-if="scope.row.new === true"
                          :prop="scope.row.email"
                          class="mb-0"
                        >
                          <el-input
                            type="text"
                            v-model="scope.row.email"
                            placeholder="Email"
                          ></el-input>
                        </el-form-item>
                        <span v-else>
                          {{ scope.row.email }}
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="80">
                    <template slot-scope="scope">
                      <el-button-group v-if="scope.row.new === true">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="addUser(scope.row, scope.$index)"
                        ></el-button>
                      </el-button-group>
                      <el-button-group v-else>
                        <el-button
                          icon="el-icon-delete"
                          @click="removeUser(scope.$index)"
                        ></el-button>
                      </el-button-group>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="col-4">
                <el-form-item
                  :label="'Expiration Time'"
                  prop="timeExp"
                  :rules="[
                    { required: true, message: 'Expiration Time is required' },
                  ]"
                >
                  <el-date-picker
                    type="date"
                    v-model="formShare.timeExp"
                    value-format="yyyy-MM-dd"
                    format="MM/dd/yyyy"
                    placeholder="Pick a date"
                    class="w-100"
                  ></el-date-picker>
                </el-form-item>
              </div>
              <div class="col-5">
                <el-form-item :label="'Password'" prop="password">
                  <el-form-item :prop="formShare.securityCode" class="mb-0">
                    <el-input
                      type="text"
                      v-model="formShare.securityCode"
                      placeholder="Password"
                    ></el-input>
                  </el-form-item>
                </el-form-item>
              </div>
              <div class="col-3">
                <center>
                  <el-button
                    :disabled="formShare.url !== null"
                    type="primary"
                    class="float-right mr-1"
                    @click="generate_url()"
                    >Generate URL</el-button
                  >
                </center>
              </div>
              <div class="col-10">
                <el-form-item :label="'Url'" prop="Url">
                  <el-input
                    type="text"
                    v-model="formShare.url"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-2">
                <el-button
                  :disabled="formShare.url === null"
                  type="primary"
                  class="float-right mr-1"
                  @click="copy_url()"
                  >Copy URL</el-button
                >
              </div>
            </div>
          </el-form>
        </div>
        <div class="col-12 mb-1">
          <el-button type="primary" class="float-right" @click="share()"
            >Share</el-button
          >
          <el-button
            type="secondary"
            class="float-right mr-1"
            @click="hide_share()"
            >Cancel</el-button
          >
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import documentManager from "@/services/api/documentManager";

export default {
  name: "modal-share",
  data() {
    return {
      formShare: {
        shareholders: [],
        items: [],
        urlUsers: [],
        parentPath: null,
        timeExp: null,
        securityCode: null,
        url: null,
        idInvitation: null,
      },
      shareContentView: {
        shareholders: [],
      },
    };
  },
  methods: {
    startShare(items) {
      documentManager.shareContent(items).then((response) => {
        const path = require("path");
        response.shareholders.forEach((shareholder) => {
          shareholder.selected = false;
          shareholder.sms = true;
        });
        let parentPath = path.dirname(items.items[0].path);
        if (parentPath === ".") {
          parentPath = "/";
        }
        this.shareContentView.shareholders = response.shareholders;
        this.formShare.items = items.items;
        this.formShare.parentPath = parentPath;
      });
      this.show_share();
    },
    addUser(row, index) {
      if (row.email === "") {
        return false;
      }
      if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(row.email)) {
        this.formShare.urlUsers.unshift({
          name: row.name,
          email: row.email,
        });
      } else {
        this.$message({
          message: "Invalid Email!",
          type: "error",
          customClass: "message-error",
        });
      }
    },
    removeUser(index) {
      this.formShare.urlUsers.splice(index, 1);
    },
    generate_url() {
      this.$refs["formshareUrl"].validate((valid) => {
        if (!valid) {
          return false;
        }

        documentManager.shareUrlGuest(this.formShare).then((response) => {
          this.$message({
            message: "Shared Succesfuly",
            type: "success",
            customClass: "message-success",
          });
          this.formShare.url = response.url;
          this.formShare.idInvitation = response.idInvitation;
        });
      });
    },
    copy_url() {
      navigator.clipboard
        .writeText(this.formShare.url)
        .then(() => {
          this.$message({
            message: "Copying to clipboard was successful!",
            type: "success",
            customClass: "message-success",
          });
        })
        .catch(() => {
          this.$message({
            message: "Could not copy text!",
            type: "error",
            customClass: "message-error",
          });
        });
    },
    share() {
      this.formShare.shareholders = this.shareContentView.shareholders.filter(
        (shareholder) => shareholder.selected
      );
      if (
        this.formShare.shareholders.length == 0 &&
        this.formShare.url === null
      ) {
        this.$message({
          message: "Select Someone To Share",
          type: "error",
          customClass: "message-error",
        });
        return false;
      }

      documentManager.share(this.formShare).then(() => {
        this.$message({
          message: "Shared Succesfuly",
          type: "success",
          customClass: "message-success",
        });
        this.hide_share();
      });
    },
    show_share() {
      let formShareInfo = {
        shareholders: [],
        items: [],
        urlUsers: [],
        parentPath: null,
        timeExp: null,
        securityCode: null,
        url: null,
        sms: 1,
        idInvitation: null,
      };
      let shareContentViewInfo = {
        shareholders: [],
      };

      this.formShare = Object.assign({}, formShareInfo);
      this.shareContentView = Object.assign({}, shareContentViewInfo);
      this.$modal.show("share-modal");
    },
    hide_share() {
      this.$modal.hide("share-modal");
      this.$emit("unselectAll");
    },
  },
};
</script>

<style lang="scss"></style>
